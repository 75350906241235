import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "page-wrapper",
  role: "application"
}
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["topBar"]),
      _createElementVNode("main", _hoisted_2, [
        _createVNode(_component_router_view)
      ]),
      _createVNode($setup["bottomBar"])
    ]),
    _createVNode($setup["LoadingModal"], { active: $setup.isLoading }, null, 8 /* PROPS */, ["active"])
  ], 64 /* STABLE_FRAGMENT */))
}