<template>
    <div class="page-wrapper" role="application">
        <topBar />
        <main class="content-wrapper">
            <router-view />
        </main>
        <bottomBar />
    </div>
    <LoadingModal :active="isLoading" />
</template>
<script setup lang="ts">
import topBar from "@/components/top-bar.vue";
import bottomBar from "@/components/bottom-bar.vue";
import { auth0 } from "@/services/auth0-client";
import { onMounted, ref, watch } from 'vue';
import store from '@/store';
import LoadingModal from "@/components/loading-modal.vue";

const isLoading = ref(auth0.isLoading.value);

watch(auth0.isLoading, (newVal) => {
    isLoading.value = newVal;
});

// Set references
let logoutTimer: number;

// Reset the timer whenever there's user activity
const resetLogoutTimer = () => {
    // Clear the existing timer
    clearTimeout(logoutTimer);
    // Start a new timer that will log the user out when it expires
    logoutTimer = setTimeout(logout, 30 * 60 * 1000); // 30 minutes in milliseconds 30 * 60 * 1000
};

// Call this function whenever there's user activity (e.g., mousemove, keypress)
const handleUserActivity = () => {
    resetLogoutTimer();
};

// Log the user out of the application
const logout = () => {
    // Clear polNbr from localstorage in the Vuex Store
    store.dispatch("updatePolNbr", "");
    // Log the user out of Auth0 using the SDK method
    auth0.logout({
        logoutParams: {
            // The clientId of your application.
            // If this property is not set, then the clientId that was used during initialization of the SDK 
            //      is sent to the logout endpoint.
            // If this property is set to null, then no client ID value is sent to the logout endpoint.
            client_id: process.env.VUE_APP_CLIENT_ID,
            // The URL where Auth0 will redirect your browser to after the logout.
            // Note: If the client_id parameter is included, 
            //      the returnTo URL that is provided must be listed in the Application's "Allowed Logout URLs" in the Auth0 dashboard.
            // However, if the client_id parameter is not included, 
            //      the returnTo URL must be listed in the "Allowed Logout URLs" at the account level in the Auth0 dashboard.
            returnTo: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
            // When supported by the upstream identity provider,
            //      forces the user to logout of their identity provider and from Auth0.
            federated: false,
        }
    });
};

// Listen for user activity events (you may add more events like keypress, etc.)
window.addEventListener('mousemove', handleUserActivity);

onMounted(() => {
    // Initialize the timer when the component is mounted
    resetLogoutTimer();
});

</script>
