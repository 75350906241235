import { ref } from "vue";

// Define the ref variables
export const enableHomeLink = ref(true); // Disables nav-link when on the Home page
export const enableNewClaimLink = ref(false); // Disables nav-link when on the New Claim page
export const enableExistingClaimsLink = ref(false); // Disables nav-link when on the Existing Claims page

export const Shared = {
	TOASTER: {
		DELETE: "Data deleted successfully",
		SAVE: "Request submitted successfully",
		UPDATE: "Data updated successfully",
		SUCCESS: "Policy details fetched successfully.",
		FAILED: "Record not found. Please enter manually.",
	},
	VALIDATION: {
		REQUIRED: "This field is required",
		MIN_1_MAX_8: "Length should be min 1 and max 8 characters",
		MAX_LENGTH_100: "Length should be max 100 characters",
		MIN_5_MAX_10: "Length should be min 5 and max 10 characters",
		MIN_2_MAX_30: "Length should be min 2 and max 30 characters",
		MIN_2_MAX_50: "Length should be min 2 and max 50 characters",
		MIN_2_MAX_80: "Length should be min 2 and max 80 characters",
		MIN_2_MAX_150: "Length should be min 2 and max 150 characters",
		MIN_2_MAX_255: "Length should be min 2 and max 255 characters",
		VALID_NAME: "Please enter a valid name",
	},
	REGEX: {
		//eslint-disable-next-line
		EMAIL: /(^$)|^([0-9a-zA-Z]((?!.*\.{2})[-\.\w]*[0-9a-zA-Z])@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/,
		ZIPCODE_5: /(^$)|(^\d{5}?(\d{4})?$)/,
		ZIPCODE_9: /(^$)|(^\d{5}([-]*)?(\d{4})?$)/,
		DIGITS: /^[0-9]*$/,
		DIGITS_WITH_FRACTION: /^[0-9.]*$/,
		NEGATIVE_DIGITS: /^[0-9-]*$/,
		NEGATIVE_DIGITS_WITH_FRACTION: /^[0-9.-]*$/,
		DIGITS_WITH_COMMA: /^[0-9,]*$/,
		BUSINESS_NAME: /^[a-z0-9]+$/i,
		PHONE_NUMBER: /(\d{0,3})(\d{0,3})(\d{0,4})/,
		CHARACTERS: /^[a-zA-Z]+$/,
		NAC_DOMAIN_REGEX: /^[A-Za-z0-9._%+-]+@nationalautocare\.com$/,
	},
	CLAIM_TABS: [
		{
			tabValue: 1,
			tabComponent: "StepOnePage",
		},
		{
			tabValue: 2,
			tabComponent: "StepTwoPage",
		},
		{
			tabValue: 3,
			tabComponent: "StepThreePage",
		},
		{
			tabValue: 4,
			tabComponent: "StepFourPage",
		},
		{
			tabValue: 5,
			tabComponent: "StepFivePage",
		},
	],
	NAME: {},
	API_URL: {
		STATES: "states",
		SOURCE_TYPES: "source-types",
		CONTRACTS: "queues",
		ADD_CONTRACT: "contract",
	},
	VALIDATIONS: {
		REQUIRED: "This field is required",
		MAX_LENGTH_1000: "Length should be max 1000 characters",
		MAX_LENGTH_50: "Length should be max 50 characters",
		LENGTH_8_9: "Policy number must be 8 or 9 digits.",
		LENGTH_10: "Length should be 10 digits.",
		PHONE_LENGTH_12: "Phone Number will only allow 12 characters.",
	},
	CONFIRMATION_MESSAGE: {
		DISCARD: "Claim request will be cancelled. Are you sure?",
		REMOVE_UPLOADED_FILE: "Uploaded file will be removed. Are you sure?",
	},
};
