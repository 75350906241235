<template>
  <teleport to="body">
    <div class="modal-backdrop fade show" @click="emit('handleNo')" role="presentation"></div>
    <div class="modal d-block" tabindex="-1" ref="modalElementRef" @keydown.esc="emit('handleNo')" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header border-0">
            <button type="submit" class="btn-close" data-bs-dismiss="modal" ref="closeButtonRef"
              @click="emit('handleNo')"></button>
          </div>
          <div class="modal-body">
            <slot name="modalContent">Modal Content</slot>
          </div>
          <div class="modal-footer justify-content-center">
            <button type="submit" class="btn btn-outline-primary px-4" @click="emit('handleNo')">
              No
            </button>
            <button type="submit" class="btn btn-primary px-4" @click="emit('handleYes')">
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";

// Emits
const emit = defineEmits([
  "handleNo",
  "handleYes",
]);

// References
const closeButtonRef = ref();

onMounted(() => {
  closeButtonRef.value.focus();
});

</script>