import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AppShell from "@/components/app-shell.vue";
import PageNotFound from "@/components/page-not-found.vue";
import Unauthorized from "@/components/unauthorized-page.vue";
import AuthCallback from "@/components/auth-callback.vue";
import { authGuard } from "@auth0/auth0-vue";
import ErrorPage from "@/components/error-page.vue";

/* Lazy loading routes */
const Home = () => import("@/components/home-page.vue");
const ClaimList = () => import("@/components/claim-list.vue");
const NewClaimAdd = () => import("@/components/new-claim-add.vue");
const ClaimSummary = () => import("@/components/claim-summary.vue");
const ClaimThankYou = () => import("@/components/claim-thank-you.vue");
const ClaimThankYouDetails = () => import("@/components/claim-thank-you-details.vue");

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		redirect: "/home",
		name: "AppShell",
		component: AppShell,
		beforeEnter: authGuard,
		children: [
			{
				path: "/home",
				name: "Home",
				component: Home,
				beforeEnter: authGuard,
			},
			{
				path: "/policies",
				name: "ClaimList",
				component: ClaimList,
				beforeEnter: authGuard,
			},
			{
				path: "/policies/:policyNumber/claims/:claimNumber",
				name: "ClaimSummary",
				component: ClaimSummary,
				beforeEnter: authGuard,
			},
			{
				path: "/claim/add",
				name: "NewClaimAdd",
				component: NewClaimAdd,
				beforeEnter: authGuard,
			},
			{
				path: "/claim/thank-you/:claimNumber",
				name: "ClaimThankYou",
				component: ClaimThankYou,
				beforeEnter: authGuard,
			},
			{
				path: "/claim/thank-you-details",
				name: "ClaimThankYouDetails",
				component: ClaimThankYouDetails,
				beforeEnter: authGuard,
			},
			{
				path: "/error",
				name: "ErrorPage",
				component: ErrorPage,
			},
			{
				path: "/testing",
				component: ClaimThankYou,
				beforeEnter: () => {
					throw new Error("This is a test router error");
				},
			},
		],
	},
	{
		path: "/auth-callback",
		name: "AuthCallback",
		component: AuthCallback,
	},
	{
		path: "/unauthorized",
		name: "Unauthorized",
		component: Unauthorized,
	},
	{
		path: "/:pathMatch(.*)*",
		name: "PageNotFound",
		component: PageNotFound,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.onError(() => {
	router.push({ name: "ErrorPage" });
});

router.beforeEach(() => {
	// reset scroll position to top
	window.scrollTo(0, 0);
});

export default router;
