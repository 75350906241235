<template>
  <div class="container" role="main">
    <div class="row">
      <div class="col text-center">
        <figure>
          <!-- Image downloaded from ShutterStock and color changed by Marketing dept -->
          <img src="@/assets/images/unknown_error.svg" class="w-50 p-3" alt="Unknown Error Image" />
        </figure>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h1 class="mb-2">Oops!</h1>
        <h4 class="mb-3">Something went wrong...</h4>
        <p class="text-secondary mb-0">
          To file your claim by phone,
        </p>
        <p class="text-secondary mb-0">
          please call our support team at <a href="tel:18339370184" class="phone-link">1-833-937-0184</a>.
        </p>
        <p class="text-secondary mb-0">
          We apologize for any inconvenience.
        </p>
        <div class="mt-3">
          <button class="btn btn-primary" type="submit" title="Home Button" @click="handleBackToHome">
            Back to Home
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import { updateNavLinksPush } from "@/router/navigation";

// This method redirect to Home Page
const handleBackToHome = () => {
  updateNavLinksPush("/home");
}
</script>
