import Vuex from "vuex";

// Create Vuex data store
const store = new Vuex.Store({
	state: {
		submitButton: true,
		loader: false,
		polNbr: null,
		connectionType: "",
		connectionName: "",
		user_id: "",
		firstName: "",
		lastName: "",
	},
	getters: {
		getSubmitButton: (state) => state.submitButton,
		getLoader: (state) => state.loader,
		getPolNbr: (state) => state.polNbr,
		getConnectionType: (state) => state.connectionType,
		getConnectionName: (state) => state.connectionName,
		getUserId: (state) => state.user_id,
		getFirstName: (state) => state.firstName,
		getLastName: (state) => state.lastName,
	},
	mutations: {
		updateSubmitButton(state, value) {
			state.submitButton = value;
		},
		LOADER(state, value) {
			state.loader = value;
		},
		setPolNbr(state, newPolNbr) {
			state.polNbr = newPolNbr;
			sessionStorage.setItem("polNbr", newPolNbr); // Store in browser's sessionStorage
		},
		setConnectionType(state, type) {
			state.connectionType = type;
		},
		setConnectionName(state, name) {
			state.connectionName = name;
		},
		setUserId(state, id) {
			state.user_id = id;
		},
		setFirstName(state, name) {
			state.firstName = name;
		},
		setLastName(state, name) {
			state.lastName = name;
		},
	},
	actions: {
		["showSubmitButton"]({ commit }) {
			commit("updateSubmitButton", true);
		},
		["hideSubmitButton"]({ commit }) {
			commit("updateSubmitButton", false);
		},
		["showLoader"]({ commit }) {
			commit("LOADER", true);
		},
		["hideLoader"]({ commit }) {
			commit("LOADER", false);
		},
		["updatePolNbr"]({ commit }, newPolNbr: string) {
			commit("setPolNbr", newPolNbr);
		},
		// Only used in main.ts
		initPolNbr({ commit }) {
			const storedPolNbr = sessionStorage.getItem("polNbr");
			if (storedPolNbr) {
				commit("setPolNbr", storedPolNbr);
			}
		},
		updateConnectionType({ commit }, type) {
			commit("setConnectionType", type);
		},
		updateConnectionName({ commit }, name) {
			commit("setConnectionName", name);
		},
		updateUserId({ commit }, id) {
			commit("setUserId", id);
		},
		updateFirstName({ commit }, name) {
			commit("setFirstName", name);
		},
		updateLastName({ commit }, name) {
			commit("setLastName", name);
		},
	},
});

export default store;
