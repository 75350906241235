<template>
  <nav class="navbar navbar-expand-sm bg-white shadow" role="navigation">
    <div class="container-fluid">
      <!-- collapsed hamburger button -->
      <button class="navbar-toggler" type="submit" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup">
        <span class="navbar-toggler-icon"></span>
      </button>
      <!-- Progressive image and nav-links -->
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
          <a class="navbar-brand" href="https://www.progressive.com" target="_blank"><img
              src="@/assets/images/progressive-logo.svg" alt="Progressive Logo" /></a>

          <a v-show="showHomeLink" v-if="enableHomeLink" class="nav-link border-link" href="javascript:void(0)"
            @click="handleNavLinkClick('/home')">
            Home
          </a>
          <a v-show="showHomeLink" v-else class="nav-link border-link disabled">Home</a>

          <a v-if="enableExistingClaimsLink" class="nav-link border-link" href="javascript:void(0)"
            @click="handleNavLinkClick('/policies')">
            Existing Claims
          </a>
          <a v-else class="nav-link border-link disabled">Existing Claims</a>

          <a v-show="showNewClaimLink" v-if="enableNewClaimLink" class="nav-link border-link" href="javascript:void(0)"
            @click="handleNavLinkClick('/claim/add')">
            New Claim
          </a>
          <a v-show="showNewClaimLink" v-else class="nav-link border-link disabled">New Claim</a>
        </div>
      </div>
    </div>
  </nav>
  <!-- Confirmation Modal -->
  <confirmation-modal v-if="isShowModal" @handleNo="handleNo" @handleYes="handleYes">
    <template #modalContent>
      <p class="fs-5 text-center">{{ Shared.CONFIRMATION_MESSAGE.DISCARD }}</p>
    </template>
  </confirmation-modal>
</template>
<script setup lang="ts">
import confirmationModal from '@/components/confirmation-modal.vue';
import { auth0 } from '@/services/auth0-client';
import { ref, onMounted, computed } from 'vue';
import { updateNavLinksPush } from '@/router/navigation';
import { Shared } from '@/constants';
import { useStore } from 'vuex';
import {
  enableHomeLink,
  enableNewClaimLink,
  enableExistingClaimsLink,
} from '@/constants';

// References
const isShowModal = ref(false);
const desiredRoute = ref('');
const store = useStore();
const connectionType = computed(() => store.getters.getConnectionType);
const connectionName = computed(() => store.getters.getConnectionName);
const PolNbr = computed(() => store.getters.getPolNbr);

// ONLY show the Home link if the user is a NAC employee
const showHomeLink = computed(() => {
  return connectionName.value === 'apco-sso' || connectionName.value === 'apco-uat-sso' || connectionType.value === 'auth0' || connectionType.value === 'waad';
});

// ONLY show the New Claim link if the user is a Progressive Customer or a NAC employee
const showNewClaimLink = computed(() => {
  return (
    connectionName.value === 'pvp-uat-sso' ||
    connectionName.value === 'pvp-sso' ||
    connectionName.value === 'apco-uat-sso' ||
    connectionName.value === 'apco-sso' ||
    connectionType.value === 'auth0' || // QA
    connectionType.value === 'waad' // DEV
  );
});

// Function to handle the user clicking a navigation link
const handleNavLinkClick = (route: string) => {
  // Add PolNbr to the route, if it exists, and the user is NOT navigating to the Home page
  if (PolNbr.value && route !== '/home') {
    desiredRoute.value = `${route}?PolNbr=${PolNbr.value}`;
  } else {
    desiredRoute.value = route;
  }
  // Check the current page
  if (window.location.pathname.includes('/claim/add')) {
    // Show a confirmation modal if the user is on the New Claim page
    isShowModal.value = true;
  } else {
    // Navigate to the new route
    updateNavLinksPush(desiredRoute.value);
  }
};

// Function to handle the user clicking the yes button on the confirmation modal
const handleYes = () => {
  isShowModal.value = false;
  updateNavLinksPush(desiredRoute.value);
};

// Function to handle the user clicking the no button on the confirmation modal
const handleNo = () => {
  isShowModal.value = false;
};

onMounted(async () => {
  if (auth0.isAuthenticated) {
    let connectionType = '', connectionName = '', user_id = '';
    // Split the subject into an array
    const subject = auth0.user.value?.sub?.split('|');
    if (subject) {
      // Set the connection type
      connectionType = subject[0];
      // If sub has 3 parts to it that means the user is using an enterprise connections (pvp-uat-sso, emp-uat-sso, apco-sso, emp-sso, pvp-sso)
      if (subject?.length === 3) {
        connectionName = subject[1];
        user_id = subject[2];
      } else if (subject?.length === 2) {
        // If sub has 2 parts to it that means the user is using the "auth0" connection, and there is no connection name
        connectionName = '';
        user_id = subject[1];
      }
      // Update the Vuex store
      store.commit('setConnectionType', connectionType);
      store.commit('setConnectionName', connectionName);
      store.commit('setUserId', user_id);
    }
    //console.info(auth0.user.value); // TESTING ONLY
    //console.info(auth0.user.value?.sub); // TESTING ONLY
  } else {
    // clears the application session and redirects to the Auth0 /v2/logout endpoint
    auth0.logout({
      logoutParams: {
        // The clientId of your application.
        // If this property is not set, then the clientId that was used during initialization of the SDK
        //      is sent to the logout endpoint.
        // If this property is set to null, then no client ID value is sent to the logout endpoint.
        client_id: process.env.VUE_APP_CLIENT_ID,
        // The URL where Auth0 will redirect your browser to after the logout.
        // Note: If the client_id parameter is included,
        //      the returnTo URL that is provided must be listed in the Application's "Allowed Logout URLs" in the Auth0 dashboard.
        // However, if the client_id parameter is not included,
        //      the returnTo URL must be listed in the "Allowed Logout URLs" at the account level in the Auth0 dashboard.
        returnTo: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
        // When supported by the upstream identity provider,
        //      forces the user to logout of their identity provider and from Auth0.
        federated: false,
      },
    });
  }
});
</script>
<style scoped>
.nav-link {
  color: #006efa;
  /* This sets the text color of the .nav-link elements to dodgerblue. dodgerblue is a predefined color in CSS and represents a shade of blue. */
  font-size: 1.1em;
  /* This sets the font size of the text within the .nav-link elements to 1.1em. The em unit is relative to the font size of the parent element. */
  font-weight: 700;
  /* This sets the font weight (thickness) of the text within the .nav-link elements to 700, which corresponds to the "bold" font weight. */
  position: relative;
  /* This sets the positioning context for the .nav-link elements to "relative". 
    This is useful when using absolute positioning within the .nav-link elements or any child elements. */
  text-decoration: none;
  /* This removes the default underline from the links inside the .nav-link elements. 
    By default, hyperlinks have underlines to indicate that they are clickable. Setting text-decoration: none; removes this underline. */
}

/* creates a hover effect that adds an underline to an element with the class .nav-link when it's being hovered over */
.nav-link::after {
  content: '';
  /* This sets the content of the pseudo-element to an empty string, effectively creating a non-intrusive element that is used purely for styling. */
  position: absolute;
  /* This positions the pseudo-element absolutely within its containing element, allowing it to overlay the .nav-link element. */
  width: 0;
  /* Initially, the width of the pseudo-element is set to 0, making it invisible. */
  height: 2px;
  /* This sets the height of the pseudo-element to 2px, creating the underline effect. */
  bottom: 0;
  /* This aligns the bottom edge of the pseudo-element with the bottom edge of the .nav-link element. */
  left: 50%;
  /* This centers the pseudo-element horizontally within the .nav-link element. This makes it look like the underline spreads out from the middle */
  background-color: #006efa;
  /* color of the underline when it's visible */
  transition:
    width 0.3s ease-in-out,
    left 0.3s ease-in-out;
  /* This specifies the transition effect that will be applied when the .nav-link element is hovered over. 
    In this case, the width and left properties will transition smoothly over 0.3s using an ease-in-out timing function.  */
}

/* This is the selector that targets the pseudo-element when the .nav-link element is being hovered over. */
.nav-link:hover::after {
  width: 100%;
  /* When the .nav-link is hovered over, this sets the width of the pseudo-element to 100%, 
    effectively stretching it to the full width of the .nav-link, creating the underline effect. */
  left: 0;
  /* When the .nav-link is hovered over, this moves the pseudo-element to the leftmost edge of the .nav-link, completing the underline effect. */
}

/* Class for the nav-link border style */
.border-link {
  border-left: 1px solid black;
}

/* Override color for .nav-link with both .border-link and .disabled classes */
.nav-link.border-link.disabled {
  color: #757575;
}
</style>
