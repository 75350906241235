<template>
  <div class="container" role="main">
    <div class="row">
      <div class="col text-center">
        <figure>
          <!-- Image downloaded from ShutterStock and color changed by Marketing dept -->
          <img src="@/assets/images/404_error.svg" class="w-50 p-3" alt="404 Error Image" />
        </figure>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h1 class="mb-3">Page Not Found!</h1>
        <p class="text-secondary mb-0">
          Maybe this page used to exist or you just spelled something wrong.
        </p>
        <p class="text-secondary mb-0">
          Chances are you spelled something wrong, please double check the URL?
        </p>
        <div class="mt-3">
          <button class="btn btn-primary" type="submit" title="Home Button" @click="handleBackToHome">
            Back to Home
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import { updateNavLinksPush } from "@/router/navigation";

// This method redirect to Home Page
const handleBackToHome = () => {
  updateNavLinksPush("/home");
}
</script>
