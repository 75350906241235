<template>
    <nav class="navbar bg-white shadow p-0 footer" title="footer" role="contentinfo">
        <div class="container-fluid">
            <p class="text-secondary">
                <!-- <i>Services provided by </i> -->
                <!-- <a href="https://apcoholdings.com/" target="_blank">
                    <img src="@/assets/images/APCOHoldings-logo.png" style="width: 64px; height: 30px;"
                        alt="APCO Holdings Logo" />
                </a> -->
            </p>
            <p class="text-secondary">
                <i>Contact <a href="tel:18339370184" class="phone-link">1-833-937-0184</a> for
                    more information</i>
            </p>
        </div>
    </nav>
</template>
<style scoped>
.footer .container-fluid {
    padding-top: 10px;
}

@media (max-width: 510px) {
    .footer .container-fluid p {
        /* Styles applied when the width is under 510px */
        margin: auto;
        padding-bottom: 20px;
        text-align: center;
    }

    .navbar {
        height: 110px;
    }
}
</style>