<template>
  <div class="page-wrapper ghost" role="complementary">
    <nav class="navbar shadow" title="auth-callback" role="navigation">
      <div class="container-fluid">
        <a class="navbar-brand ghost__logo" href="#"></a>
        <div class="btn-group user__profile d-flex align-items-center">
          <span class="user__name d-md-flex d-none"></span>
        </div>
      </div>
    </nav>
    <main class="content-wrapper">
      <div v-if="auth0.isLoading.value" class="loader-container" role="alert">
        <div class="loader">Authenticating...</div>
      </div>
    </main>
  </div>
</template>
<script setup lang="ts">
import { auth0 } from "@/services/auth0-client";
import { updateNavLinksPush } from "@/router/navigation";

auth0.handleRedirectCallback()
  .then(() => {
    // Redirect the user to the home page after authentication
    updateNavLinksPush("/home");
  });

</script>
