import { enableHomeLink, enableNewClaimLink, enableExistingClaimsLink } from "@/constants";
import store from "@/store";
import router from "@/router";
import { computed } from "vue";

// Get Policy Number from Vuex Store (query string parameter)
const PolNbr = computed(() => store.getters.getPolNbr);

// Naviate to the new URL
const updateNavLinksPush = (route: string) => {
	// Enable/disable the navigation links
	updateNavLinks(route);

	// Check if the user is a Progressive customer or employee
	if (
		store.getters.getConnectionName == "emp-uat-sso" ||
		store.getters.getConnectionName == "emp-sso" ||
		store.getters.getConnectionName == "pvp-uat-sso" ||
		store.getters.getConnectionName == "pvp-sso"
	) {
		// Check if the Policy Number was passed in the query string
		if (PolNbr.value) {
			// Home Link is not available to Progressive customers and employees
			if (route == "/" || route.toLowerCase().includes("home")) {
				// Navigate to the Existing Claims page
				enableExistingClaimsLink.value = false;
				router.push("/policies?polNbr=" + PolNbr.value);
			} else {
				// Navigate to the URL
				router.push(route);
			}
		} else {
			// Policy Number does not exist in the query string
			// Don't let Progressive customers or employees navigate to any page
		}
	} else {
		// APCO Employees can navigate to any page, but let's add the policy number to the query string
		if (PolNbr.value) {
			if (route.includes("/claim/add")) {
				router.push("/claim/add?polNbr=" + PolNbr.value);
			} else if (route.includes("/policies")) {
				router.push("/policies?polNbr=" + PolNbr.value);
			} else {
				router.push(route);
			}
		} else {
			router.push(route);
		}
	}
};

// Enable/disable the navigation links based on the route
const updateNavLinks = (route: string) => {
	// Enable all of the navigation links by default
	enableHomeLink.value = true;
	enableExistingClaimsLink.value = true;
	enableNewClaimLink.value = true;

	// Check the route and hide the appropriate links
	if (route.includes("/claim/add")) {
		// Navigating to the New Claim page, disable the New Claim link
		enableNewClaimLink.value = false;
	} else if (route.includes("/policies")) {
		// Navigating to the Existing Claims page, disable the Existing Claims link
		enableExistingClaimsLink.value = false;
	} else if (route.includes("thank-you") || route.includes("error")) {
		// All visible links will be enabled
	} else {
		// Navigating to the Home page, disable the Home link
		enableHomeLink.value = false;
	}
};

export { updateNavLinksPush, updateNavLinks };
