import { createAuth0 } from "@auth0/auth0-vue";

// function to get URL parameters
function getURLParameter(name: string): string | null {
	const params = new URLSearchParams(window.location.search);
	return params.get(name);
}

// read the 'connection' parameter from the URL
const urlConnection = getURLParameter("connection");
let connectionToUse;

// check if the URL parameter matches the secondary connection, if not default to the secondary connection
if (urlConnection === "emp-uat-sso" || urlConnection === "emp-sso") {
	connectionToUse = process.env.VUE_APP_EMP_CONNECTION;
} else if (urlConnection === "apco-uat-sso" || urlConnection === "apco-sso") {
	connectionToUse = process.env.VUE_APP_APCO_CONNECTION;
} else {
	connectionToUse = process.env.VUE_APP_CONNECTION; // pvp-uat-sso, pvp-sso, or empty string when running Locally, DEV, or QA
}

export const auth0 = createAuth0({
	domain: process.env.VUE_APP_AUTH0_DOMAIN, // https://...us.auth0.com
	clientId: process.env.VUE_APP_CLIENT_ID,
	cacheLocation: "localstorage", // provides persistence across page refreshes and browser tabs
	useCookiesForTransactions: true, // adds ability to authenticate using flows that may end up spanning across multiple tabs
	authorizationParams: {
		audience: process.env.VUE_APP_AUDIENCE, // urn:uat-nac-api
		redirect_uri: process.env.VUE_APP_REDIRECT_URI, // http://.../auth-callback
		scope: process.env.VUE_APP_SCOPE, // openid profile email offline_access
		// WHEN USING PRG's startSSO URL connection CANNOT BE AN EMPTY STRING
		connection: connectionToUse, // enterprise connection name
	},
});
