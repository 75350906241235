<template>
  <div class="container" role="main">
    <div class="row">
      <div class="col text-center">
        <figure>
          <!-- Image downloaded from ShutterStock and color changed by Marketing dept -->
          <img src="@/assets/images/401_error.svg" class="w-50 p-3" alt="401 Error Image" />
        </figure>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h1 class="mb-3">Unauthorized Access!</h1>
        <p class="text-secondary mb-0">
          You will be logged out in {{ counter }} seconds.
        </p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">

import { onBeforeMount, ref } from "vue";
import { auth0 } from "@/services/auth0-client";

const counter = ref(5);

// OnBeforeMount Method
onBeforeMount(() => {
  // Set Counter
  setInterval(() => {
    counter.value > 0 && counter.value--;
    if (counter.value == 0) {
      // clears the application session and redirects to the Auth0 /v2/logout endpoint
      auth0.logout({
        logoutParams: {
          // The clientId of your application.
          // If this property is not set, then the clientId that was used during initialization of the SDK 
          //      is sent to the logout endpoint.
          // If this property is set to null, then no client ID value is sent to the logout endpoint.
          client_id: process.env.VUE_APP_CLIENT_ID,
          // The URL where Auth0 will redirect your browser to after the logout.
          // Note: If the client_id parameter is included, 
          //      the returnTo URL that is provided must be listed in the Application's "Allowed Logout URLs" in the Auth0 dashboard.
          // However, if the client_id parameter is not included, 
          //      the returnTo URL must be listed in the "Allowed Logout URLs" at the account level in the Auth0 dashboard.
          returnTo: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
          // When supported by the upstream identity provider,
          //      forces the user to logout of their identity provider and from Auth0.
          federated: false,
        }
      });
    }
  }, 1000);
});

</script>
